.c-container {
    justify-content: space-between;
}

.c-container>div {
    flex: 1;
}

.c-right {
    width: 100%;
}

.c-left {
    gap: 0.5rem;
}

.contactModes .row {
    gap: 1.5px;
}

.mode {
    width: 16rem;
    padding: 1rem;
    /* border: 1px solid black; */
    border-radius: 5px;
    gap: 1rem;
    transition: all 300ms ease-in;
}

.mode .button {
    width: 100%;
    background: lightblue;
    color: blue;
    font-size: 1rem;
    font-weight: 600;
}

.mode> :nth-child(1) {
    width: 100%;
    gap: 1.6rem;
}

.mode .detail .primaryText {
    font-size: 1.1rem;
    font-weight: 600;
}

.mode:hover {
    scale: 1.1;
    box-shadow: var(--shadow);
}

.mode .button:hover {
    background-color: blue;
    color: aliceblue;
    scale: 0.8;
}

@media (max-width: 1024px) {
    .c-container {
        flex-direction: column;
    }

    .c-right {
        justify-content: center;
    }

    .row {
        flex-direction: column;
    }

    /* .mode {
    width: 100%;
  } */
}